<template>
  <div class="wrapper fall-workshop">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #2c2a26"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">UMC Fall Workshop</h2>
            <h3>Becoming Awestruck: Igniting the Science of Transcendence in Montessori Communities</h3>
            <h4>Saturday, September 21, 2024<br />8:30 AM - 3:00 PM</h4>
            <h4>
              Elizabeth Academy<br />2870 Connor Street<br />Salt Lake City
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <a
          target="_blank"
          class="btn btn-primary btn-lg text-white"
          href="downloads/Fall Workshop 2024.pdf"
          ><strong>Download Flyer</strong></a
        >
        <a
          target="_blank"
          class="btn btn-primary btn-lg text-white"
          href="downloads/Becoming Awestruck Handout.pdf"
          ><strong>Download Handout</strong></a
        >
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h4 class="title">Schedule</h4>
            <p><b>8:30 - 9:00 AM: Check-in, Continental Breakfast</b></p>
            <p>
              <b
                >9:15- 9:30 AM: Opening</b
              >
            </p>
            <p><b>9:30 - 11:30 AM: Keynote: Tammy Oesting</b></p>
            <p>
              <b
                >Unlocking Awe</b
              >
            </p>
            <p>
              Join Tammy Oesting, M.Ed., a renowned Montessori researcher, speaker, and educator, on an immersive journey that promises to ignite your sense of wonder and awe. Tammy’s research unveils the profound impact of awe on learning and emphasized its pivotal role in fostering connectivity among individuals. In this interactive workshop, delve into the science behind awe and its significance in our lives as educators. Tammy seamlessly weaves together her extensive research findings with decades of awe-inspired experiences, illustrating how you can integrate awe and wonder into your Montessori practices.
            </p>
            <p><b>11:30 - 12:30 PM: Lunch</b></p>
            <p><b>12:30 - 2:30 PM: Breakout Sessions</b></p>
            <p>
              Infant/Toddler – Katie Bavasi & Richelle Segura (Soaring Wings International Montessori School)
            </p>
            <p>
              Early Childhood / Elementary - Povi Mitchell & Tenasha C. Ansera (Keres Children’s Learning Center & Indigenous Montessori Institute)
            </p>
            <p>
              Middle School/High School – Kat Kirk (Elizabeth Academy)
            </p>
            <p>Administrators – Tammy Oesting</p>
            <p><b>2:30 - 3:00 PM: Closing</b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h4 class="title">Registration Information</h4>
            <p>$95 (Includes Lunch)</p>
            <p><b>Final registration deadline is September 15th</b></p>
            <p>
              Our volunteers are working to bring you a professional conference
              at the lowest possible cost. Therefore we cannot offer refunds.
            </p>
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="https://form.jotform.com/231056427007145"
              ><strong>Register Now!</strong></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from "@/components";

export default {
  name: "fall-workshop",
  bodyClass: "fall-workshop",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

  data() {
    return {
      form: {
        firstName: "",
        email: "",
        phone: "",
      },
    };
  },
  metaInfo: {
    title: "Utah Montessori - Fall Workshop",
    meta: [
      { name: "description", content: "Sign up for the UMC Fall Workshop." },
    ],
  },
};
</script>
<style></style>
